import { createSlice } from "@reduxjs/toolkit";

const initialState = { open: false, msg: "", severity: "info" };

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    createAlert: (state, { payload }) => {
      state.open = true;
      state.msg = payload;
      state.severity = "warning";
    },
    openAlert: (state) => {
      state.open = true;
    },
    closeAlert: (state) => {
      state.open = false;
    },
    setAlertMessage: (state, { payload }) => {
      state.msg = payload;
    },
    setAlertSeverity: (state, { payload }) => {
      state.severity = payload;
    },
  },
});

export const {
  createAlert,
  openAlert,
  closeAlert,
  setAlertMessage,
  setAlertSeverity,
} = alertSlice.actions;

export default alertSlice.reducer;
