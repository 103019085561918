import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#000",
    },
  },
  // overrides: {
  //   MuiButton: {
  //     root: {
  //       background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  //       border: 0,
  //       borderRadius: 3,
  //       boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  //       color: "#000",
  //       height: 48,
  //       padding: "0 30px",
  //     },
  //   },
  // },
});

export default function LinearBuffer() {
  const [progress, setProgress] = React.useState(40);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          style={{ height: 20 }}
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
        />
        <div style={{ display: "flex", justifyContent: "center", margin: 4 }}>
          Loading...
        </div>
      </Box>
    </ThemeProvider>
  );
}
