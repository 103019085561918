import { configureStore } from "@reduxjs/toolkit";

import sideStatusReducer from "./features/status/sideStatusSlice";
import alertReducer from "./features/status/alertSlice";

const store = configureStore({
  reducer: { sideStatus: sideStatusReducer, alert: alertReducer },
});

export default store;
