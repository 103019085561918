import { Switch, Route, BrowserRouter } from "react-router-dom";
import React, { Suspense, lazy } from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { WidthContext } from "./Helper/Context";
import LinearBuffer from "./UiComponents/Progress/LinearBuffer";

const FindAuctionPage = lazy(() =>
  import("./Container/FindAuction/FindAuctionPage")
);
// import FindAuctionPage from "./Container/FindAuction/FindAuctionPage";
// import MultipleListingPage from "./Container/MultipleListing/MultipleListingPage";
const MultipleListingPage = lazy(() =>
  import("./Container/MultipleListing/MultipleListingPage")
);
// import SingleListing from "./Container/SingleListing/SingleListing";
const SingleListing = lazy(() =>
  import("./Container/SingleListing/SingleListing")
);

// import Auctioneer from "./Container/AuctioneerDirectory/Auctioneer";
const Auctioneer = lazy(() =>
  import("./Container/AuctioneerDirectory/Auctioneer")
);

// import AuctioneerListPage from "./Container/AuctioneerDirectory/AuctioneerListPage";
const AuctioneerListPage = lazy(() =>
  import("./Container/AuctioneerDirectory/AuctioneerListPage")
);

// import AuctioneerMap from "./Container/AuctioneerDirectory/AuctioneerMap";
const AuctioneerMap = lazy(() =>
  import("./Container/AuctioneerDirectory/AuctioneerMap")
);

// // import AuctionVideoPage from "./Container/BlogAndNewsletter/Video/AuctionVideoPage";
// const AuctionVideoPage = lazy(() =>
//   import("./Container/BlogAndNewsletter/Video/AuctionVideoPage")
// );

// // import BlogAndNewsEntry from "./Container/BlogAndNewsletter/BlogAndNewsEntry/BlogAndNewsEntry";
// const BlogAndNewsEntry = lazy(() =>
//   import("./Container/BlogAndNewsletter/BlogAndNewsEntry/BlogAndNewsEntry")
// );

// // import NewsCollectionPage from "./Container/BlogAndNewsletter/News/NewsCollectionPage";
// const NewsCollectionPage = lazy(() =>
//   import("./Container/BlogAndNewsletter/News/NewsCollectionPage")
// );

// // import HowToBuyPage from "./Container/BlogAndNewsletter/HowTo/HowToBuyPage";
// const HowToBuyPage = lazy(() =>
//   import("./Container/BlogAndNewsletter/HowTo/HowToBuyPage")
// );

// // import HowToSellPage from "./Container/BlogAndNewsletter/HowTo/HowToSellPage";
// const HowToSellPage = lazy(() =>
//   import("./Container/BlogAndNewsletter/HowTo/HowToSellPage")
// );

// // import TipForUsPage from "./Container/CTA/TipForUsPage";
// const TipForUsPage = lazy(() => import("./Container/CTA/TipForUsPage"));

// // import ContactUsPage from "./Container/CTA/ContactUsPage";
// const ContactUsPage = lazy(() => import("./Container/CTA/ContactUsPage"));

// // import AdvertiseWithUsPage from "./Container/CTA/AdvertiseWithUs/AdvertiseWithUsPage";
// const AdvertiseWithUsPage = lazy(() =>
//   import("./Container/CTA/AdvertiseWithUs/AdvertiseWithUsPage")
// );

// // import AuctionLawsReviewPage from "./Container/CTA/AuctionLawsReviewPage";
// const AuctionLawsReviewPage = lazy(() =>
//   import("./Container/CTA/AuctionLawsReviewPage")
// );

// // import HostDevServicesPages from "./Container/Services/HostAndDevServices/HostDevServicesPages";
// const HostDevServicesPages = lazy(() =>
//   import("./Container/Services/HostAndDevServices/HostDevServicesPages")
// );

// import Analytics from "./Container/Auctioneer/pages/Analytics/Analytics";
// const Analytics = lazy(() =>
//   import("./Container/Auctioneer/pages/Analytics/Analytics")
// );

// // import BidderDashboard from "./Container/Bidder/pages/BidderDashboard/BidderDashboard";
// const BidderDashboard = lazy(() =>
//   import("./Container/Bidder/pages/BidderDashboard/BidderDashboard")
// );

// import FullImageGalleryPage from "./Container/SingleListing/FullImageGalleryPage";
const FullImageGalleryPage = lazy(() =>
  import("./Container/SingleListing/FullImageGalleryPage")
);

// // import PrivacyPolicyPage from "./Container/FooterPages/PrivacyPolicyPage";
// const PrivacyPolicyPage = lazy(() =>
//   import("./Container/FooterPages/PrivacyPolicyPage")
// );

// // import TermsConditionPage from "./Container/FooterPages/TermsConditionPage";
// const TermsConditionPage = lazy(() =>
//   import("./Container/FooterPages/TermsConditionPage")
// );

// // import FAQPage from "./Container/FooterPages/FAQPage";
// const FAQPage = lazy(() => import("./Container/FooterPages/FAQPage"));

// // import CopyrightPage from "./Container/FooterPages/CopyrightPage";
// const CopyrightPage = lazy(() =>
//   import("./Container/FooterPages/CopyrightPage")
// );

// // import DataSecurityPage from "./Container/FooterPages/DataSecurityPage";
// const DataSecurityPage = lazy(() =>
//   import("./Container/FooterPages/DataSecurityPage")
// );

// // import AboutAJPage from "./Container/FooterPages/AboutAJPage";
// const AboutAJPage = lazy(() => import("./Container/FooterPages/AboutAJPage"));

// import RequestHandler from "./Container/RequestHandler";
// const RequestHandler = lazy(() => import("./Container/RequestHandler"));

// const Blogs = lazy(() => import("./Container/Blogs/Home"));
// const FeaturedBlogs = lazy(() =>
//   import("./Container/Blogs/Home/FeaturedBlogs")
// );
// const BlogmoreMain = lazy(() => import("./Container/Blogs/Home/blogmore"));
// const Blog = lazy(() => import("./Container/Blogs/Home/blog1"));

// const BidRequests = lazy(() =>
//   import("./Container/Auctioneer/pages/BidRequests/BidRequests")
// );

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

function App() {
  const width = useWidth();

  return (
    <WidthContext.Provider value={{ width }}>
      <BrowserRouter>
        <Switch>
          <Suspense
            fallback={
              <div>
                <LinearBuffer />
              </div>
            }
          >
            <Route exact path="/" component={FindAuctionPage} />
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/findAuctions" component={FindAuctionPage} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route
                exact
                path="/multipleListing"
                component={MultipleListingPage}
              />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/singleListing" component={SingleListing} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/auctioneerBio" component={Auctioneer} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route
                exact
                path="/auctioneerListing"
                component={AuctioneerListPage}
              />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route
                exact
                path="/auctioneerDirectories"
                component={AuctioneerMap}
              />
            </Suspense>
            {/* <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/videos" component={AuctionVideoPage} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/blogs" component={Blogs} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route path="/featuredblog" component={FeaturedBlogs} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route path="/blogmore" component={BlogmoreMain} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route path="/blog" component={Blog} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route
                exact
                path="/newsAndEvents"
                component={NewsCollectionPage}
              />
            </Suspense> */}
            {/* <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/howToBuy" component={HowToBuyPage} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/howToSell" component={HowToSellPage} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/gotTips" component={TipForUsPage} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/contactUs" component={ContactUsPage} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/advertise" component={AdvertiseWithUsPage} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/services" component={HostDevServicesPages} />
            </Suspense> */}
            <Suspense fallback={<div>Loading...</div>}>
              <Route
                exact
                path="/imageGallery"
                component={FullImageGalleryPage}
              />
            </Suspense>
            {/* <Suspense fallback={<div>Loading...</div>}>
              <Route path="/auctioneerdashboard" component={Analytics} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/bidRequests" component={BidRequests} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route path="/bidderdashboard" component={BidderDashboard} />
            </Suspense> */}
            {/* <Suspense fallback={<div>Loading...</div>}>
              <Route
                exact
                path="/lawsAndReview"
                component={AuctionLawsReviewPage}
              />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route
                exact
                path="/privacyPolicy"
                component={PrivacyPolicyPage}
              />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route
                exact
                path="/termsAndConditions"
                component={TermsConditionPage}
              />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/faq" component={FAQPage} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/copyrightPolicy" component={CopyrightPage} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/dataSecurity" component={DataSecurityPage} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/aboutAJ" component={AboutAJPage} />
            </Suspense> */}
            {/* <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/request" component={RequestHandler} />
            </Suspense> */}
          </Suspense>
        </Switch>
      </BrowserRouter>
    </WidthContext.Provider>
  );
}

export default App;
